import { ref } from '@vue/composition-api'

import LoadContainer from './components/loadContainer'

export default {
  name: 'containerUpdate',
  components: {
    LoadContainer,
  },
  props: {
    title: String,
    svg: String,
    keyName: String,
    btnTitle: String,
    items: {
      type: Array,
      default: () => ([
        'Исправлены уязвимости',
        'Улучшена система защиты',
        'Новые функции',
      ]),
    },
  },
  setup(props, ctx) {
    const { emit } = ctx
    const loadWindow = ref(false)
    const close = () => {
      loadWindow.value = false
      emit('finish', { keyName: props.keyName })
    }
    return {
      loadWindow,
      close,
    }
  },
}
