import { ref } from '@vue/composition-api'

export default {
  name: 'loadContainer',
  props: {
    btnTitle: String,
  },
  setup() {
    const disabled = ref(true)

    return {
      disabled,
    }
  },
}
